// Fonts
@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('../fonts/subset-HelveticaNeueLTPro-MdCn.woff2') format('woff2'),
    url('../fonts/subset-HelveticaNeueLTPro-MdCn.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('../fonts/subset-HelveticaNeueLTPro-Cn.woff2') format('woff2'),
    url('../fonts/subset-HelveticaNeueLTPro-Cn.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Pro';
  src: url('../fonts/subset-HelveticaNeueLTPro-BdCn.woff2') format('woff2'),
    url('../fonts/subset-HelveticaNeueLTPro-BdCn.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
