@import "mixins/mixins-master";
@import "variables";
@import "keyframes";
@import "fonts";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

html {
	min-height: 100%;
}

body {
	font-family: "Roboto", sans-serif;
	color: $blue;
	text-align: center;
	background: linear-gradient(331deg, #F9C15B 0%, #F69F12 42.19%, #F69F12 60.82%, #F9C15B 77.6%);
		overflow-x: hidden;
	min-height: 100%;

}

.bg-holder {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: url("../img/bg-desktop-23.png");
	background-size: 1968px 1947px;
	background-position: center top;
	background-repeat: no-repeat;
	z-index: -1;

	@include mobile {
		background-image: url("../img/bg-mobile-23.png");
		background-size: 796px 1759px;
		background-position: center 500px;

	}
}

* {
	box-sizing: border-box;
}

.upper-bg {
	position: absolute;
	top: 0;
	left: 0;
	height: 466px;
	width: 100%;
	background-image: url("../img/bg-upper.svg");
	background-size: 2733px 1197px;
	background-position: center bottom;
	background-repeat: no-repeat;
	z-index: -2;
	overflow: hidden;
}

.envelopes {
	background-image: url("../img/envelopes.svg");
	background-size: 100% 100%;
	width: 1920px;
	height: 282px;
	position: absolute;
	top: 100px;
	left: 50%;
	transform: translateX(-50%);
}

.container {
	width: 100%;
	max-width: 1172px;
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;
}

.inner-container {
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
}

.header-upper {
	padding-top: 74px;
	text-align: center;

	@include mobile {
		text-align: center;
	}
}

.logo-camapaign {
	width: 292px;
	height: 395px;
	display: block;
	margin: -31px auto 66px;

	@include mobile {
		width: 156px;
		height: 210px;
		margin: 45px auto;
	}
}

.main-title {
	font-weight: 700;
	font-size: 60px;
	line-height: 70px;
	color: $yellow;
	margin-bottom: 18px;
	@include mobile {
		font-size: 33px;
		line-height: 39px;
		margin-bottom: 11px;
	}
}
.main-subtitle {
	font-size: 21px;
	line-height: 25px;
	font-weight: 400;
	margin-bottom: 10px;

	@include mobile {
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 10px;
	}
}

.question {
	font-size: 29px;
	line-height: 34px;
	font-weight: 700;
	margin-bottom: 22px;

	span {
		font-size: 22px;
		line-height: 26px;
		font-weight: 400;
		display: block;
		margin-top: 10px;
	}
	@include mobile {
		font-size: 21px;
		line-height: 25px;
	}
}

input[type="text"] {
	border: none;
	border-bottom: 1px solid #a6a8ab;
	width: 100%;
	max-width: 555px;
	margin-bottom: 16px;
	text-align: center;
	font-size: 36px;
	font-weight: bold;
	color: #153567;
	outline: none;
	line-height: 60px;

	&.error {
		border-bottom: 1px solid #c42a1f;
	}
}

.spacer {
	margin-bottom: 90px;
}

textarea {
	border: none;
	border-bottom: 1px solid #a6a8ab;
	width: 100%;
	max-width: 780px;
	margin: 0 auto 60px;
	display: block;
	height: 200px;
	outline: none;
	font-family: "Roboto", sans-serif;
	color: $blue;
	font-size: 16px;
	line-height: 36px;

	&.error {
		border-bottom: 1px solid #c42a1f;
	}
}

.check {
	display: flex;
	flex-flow: row;
	justify-content: center;
	margin-top: 30px;
	flex-wrap: wrap;

	input {
		position: absolute;
		left: -99999px;
	}

	.checkbox-group {
		width: 130px;
		margin-bottom: 36px;

		@include mobile {
			width: 50%;
		}
	}

	label {
		width: 120px;
		cursor: pointer;
		&:before {
			content: "";
			display: block;
			border: 2px solid #0047B9;
			border-radius: 15px;
			width: 55px;
			height: 55px;
			margin: 0 auto 15px;
		}
	}

	input:checked + label:before {
		background-color: #0047B9;
		background-image: url("../img/done.svg");
		background-size: 17px 13px;
		background-position: center center;
		background-repeat: no-repeat;
	}
}

input[type="submit"] {
	color: #fff;
	background: #0047B9;
	border: none;
	height: 45px;
	line-height: 45px;
	padding: 0 45px;
	border-radius: 15px;
	cursor: pointer;
	font-size: 16px;
}

.thanx {
	font-size: 24px;
	line-height: 28px;
	color: #fff;

	@include mobile {
		font-size: 20px;
		line-height: 24px;
	}

	span {
		color: #0047B9;
	}
}

.validation-error {
	color: #c42a1f;
}

.content-box {
	background: #fff;
	border-radius: 25px;
	padding: 63px 30px;
	color: #153567;
	margin-bottom: 55px;
	@include mobile {
		padding: 41px 30px;
		margin-bottom: 38px;
	}
}

.logo-2023 {
	background-image: url("../img/top-2023.png");
	height: 344px;
	background-size: auto 100%;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 58px;
	@include mobile {
		background-image: url("../img/top-2023.png");
		margin-bottom: 20px;
		height: 144px;
	}
}

.help {
	color: #808285;
}


h1 {
	color: #fff;
}

.thankyou {
	h1 {
		font-weight: normal;
		font-size: 50px;
		line-height: 112%;
		color: #0047B9;

		@include mobile {
			font-size: 28px;
		}	
	}

	.logo-2023 {
		height: 430px;
		margin-bottom: 35px;
		margin-top: 80px;


		@include mobile {
			background-image: url("../img/top-2023.png");
			margin-bottom: 20px;
			height: 144px;
		}
	}
}